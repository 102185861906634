.transact {
  .transact-wrapper{
    width: 400px;
    margin: 0 auto;
    .transact-amount {
      .transact-literal {
        border: none;
        width: 100%;
        margin-bottom: 4rem;
        margin-top: 3rem;
        display: block;
        border: none;
        border-bottom: 2px solid $primary;
        display: block;
        height: 50px;
        font-weight: 700;
        text-align: center;
        font-size: 2rem;
      }
    }
    .transact-accounts {
      margin-top: 3rem;
      margin-bottom: 2rem;
      .transact-account {
        margin-bottom: 2rem;
      }
    }
    .transact-options {
      margin-top: 5rem;
    }
  }
  .purpose-select {
    .purpose-check {
      padding: 0.5rem 0.25rem;
      border: 1px solid rgba(0,0,0,.1);
      margin-bottom: 0.5rem;
      .purpose-dot {
        background: #E7E7F2;
        height: 20px;
        width: 20px;
        display: inline-block;
        border-radius: 50%;
        vertical-align: sub;
        margin-right: 5px;
      }
      .purpose-name {
        color: #999999;
        text-transform: capitalize;
      }
      transition: 0.25s linear;
      &:hover {
        cursor: pointer;
      }
    }
    .purpose-check.selected {
      background-color: #E7E7F2!important;
      border: none;
      .purpose-dot.selected {
        background: #6e6ee8!important;
      }
      .purpose-name {
        color: #000;
      }
    }
  }
}
.details-col {
  border-left: 2px solid rgba(0, 0, 0, 0.25);
}

.account-select {
  .selected-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 2px;
    .acc-details {
      display: inline-block;
      span {
        display: block;
      }
      .acc-name {
        font-weight: 600;
        font-size: 1rem;
      }
      .acc-balance {
        font-size: 0.7rem;
      }
    }
    .bank-logo{
      height: 64px;
      width: 64px;
      padding: 5px;
    }
    .custom-caret {
      position: absolute;
      right: 10px;
      font-size: 1.5rem;
      color: #888888
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.accounts-dropdown {
  position: relative;
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  border-right: 1px solid rgba(0,0,0,.1);
  border-left: 1px solid rgba(0,0,0,.1);
  .account-selection {
    padding: 0.25rem 1rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
    &:hover{
      cursor: pointer;
      background-color: $primary;
      color: #fff;
    }
  }
}