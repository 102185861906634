/* Navbar styles */
.navbar-light{
    .navbar-nav {
      .nav-link:not(.active){
        color: #000!important;
      }
      .nav-link.active {
        color: $primary!important;
      }
    }
}

.navbar-vertical {
  border-right: none!important;
  box-shadow: 0px 0 12px -3px rgba(0,0,0,0.25)!important;
}


button:focus, :focus {
  outline: none;
}

.tab-nav {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  .tab.tab-btn {
    width: 125px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888888;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
  .tab.tab-btn.active {
    border-bottom: 2px solid #191826;
    margin-top: 2px;
    span {
      color: #191826;
    }
  }
}

.query-col {
  box-shadow: 0px 1px 2px rgba(136, 136, 136, 0.24);
  border-radius: 4px;
}

.tab-col {
  // padding: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.query-sidebar {
  padding: 0 0.5rem 2rem 0.5rem;
  background: #fff;
  border-radius: 4px;
}

/* Balances chart dropdown items */
.balance-display, .balance-change-card, .cash-flow, .reports, .alert-body, .transact, .dashboard, .settings, .connect, .team, .data {
  .dropdown > button {
    all: unset;
    display: inline-block;
    width: fit-content;
    padding: 0 0.25rem;
    color: #109CF1;
    &:active {
      background-color: none!important;
      background: none!important;
      color: #109CF1!important;
    }
  }
}

.custom-input {
  &__selection {
    border: none;
  }
  &__selection, &__text, &__date, &__checkbox {
    position: relative;
  }
  &__text.disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  &__checkbox {
    height: 30px;
  }
  &__regular-input {
    all: unset;
  }
  &__select-label, &__text-label, &__date-label, &__checkbox-label {
    text-indent: 0;
    position: absolute;
    color: #4b4b4b;
    top: 3px;
    font-size: 12px;
    display: block;
    min-width: 200px;
  }
  &__checkbox-label {
    left: 25px;
    font-size: 1rem;
  }
  &__checkbox-input {
    display: block;
    position: absolute;
    top: 9px;
    left: 5px;
    margin: 0;
  }
  &__selected-option, &__regular-input, &__date {
    width: 100%;
    display: block;
    padding-bottom: 0.2rem;
  }
  &__date {
    .react-datepicker-wrapper {
      width: calc(100% - 21px);
      .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;
        input {
          width: 100%;
          border: 0;
          font-size: 0.875rem;
          margin-top: 16px;
        }
      }
    }
  }
  &__options, &__suggestion-wrapper {
    width: 99%;
    background-color: #fff;
    z-index: 101;
    border-right: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
    position: absolute;
    margin-top: -1px;
    right: 0.5%;
    display: block;
    max-height: 350px;
    overflow-y: scroll;
  }
  &__selected-option {
    font-size: 0.875rem;
    position: relative;
    span {
      display: block;
      margin-top: 1rem;
    }
    &:hover {
      cursor: pointer;
    }
    svg {
      position: absolute;
      right: 10px;
      top: 1.5rem;
      color: #909090;
    }
  }
  &__regular-input {
    margin-top: 15px;
  }
  &__date, &__text, &__selected-option {
    border: 2px solid #E2E2E2;
    height: calc(4rem + 2px);
    padding-top: calc(0.25rem + 7px);
    border-radius: 5px;
  }
  &__option {
    padding: 0.25rem 1rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
    &:hover {
      cursor: pointer;
      background-color: $primary;
      color: #fff;
    }
    span {
      font-size: 15px;
    }
  }
  // Custom input with suggestions options
  &__suggestion-wrapper {
    cursor: pointer;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    z-index: 100;
    margin-top: 15px;
    width: 100%;
    max-height: 350px;
  }
  &__suggestion {
    padding: 0.25rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .suggestion-company {
      font-size: 15px;
    }
    &:hover {
      background: $primary;
      cursor: pointer;
      span {
        color: #fff;
      }
    }
  }
}

/* Custom command bar navbar styles */
.navbar-maytana {
  font-weight: 700;
  font-size: 1rem;
  color: white;
  text-transform: capitalize;
}

.navbar-maytana:hover {
  color: white;
}

.active-navbar {
  font-weight: 1200;
  color: white;
}

.white-placeholder::-webkit-input-placeholder {
  color: #fff;
  font-weight: 500;
  opacity: 1;
}
.white-placeholder:focus::-webkit-input-placeholder {
  color: transparent;
}



/* Bootstrap toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-conversation {
  background-color: inherit;
  border: none;
}

.sendbird-theme--light .sendbird-user-message--outgoing .sendbird-user-message__text-balloon {
  background-color: #dfe9ff;
}

.sendbird-user-message--outgoing .sendbird-user-message__text-balloon {
  border-radius: 5px;
}

.sendbird-user-message--incoming .sendbird-user-message__text-balloon {
  border-radius: 5px;
}

.sendbird-message-status__icon {
  display: none;
}

.sendbird-modal {
  z-index: 5
}

.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:focus {
  border: solid 1px #007AFF;
}

.sendbird-message-input .sendbird-message-input--textarea {
  border-radius: 0;
}

.rc-mentions {
  font-size: 16px;
  width: 100%;
  border-radius: 0;
}

.rc-mentions > textarea {
  font-size: 16px;
  padding: 10px;
}

.sendbird-conversation__messages {
  max-height: 200px;
}

.sendbird-outgoing-og-message__thumbnail {
  display: none;
}

.sendbird-outgoing-og-message__og-tag { 
  display: none;
}