

/* Custom transactions table styles*/
.tx-details {
  background-color: #F4F6FB;
}


.note-tx-details {
  box-shadow: none;
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}


.tx-table {
  padding-left: 25px;
  padding-right: 25px;
  .tx-sub-header {
    margin-top: 30px;
    margin-bottom: 10px;
    h2 {
      font-weight: 400;
    }
  }
  .tx-row{
    .clickable {
      cursor: pointer;
    }
    &:first-child {
      margin-top: 20px;
    }
    .row {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: -25px;
      margin-right: -25px;
      padding-left: 25px;
      padding-right: 25px;
      &:hover {
        background-color: #F4F6FB;
      }
    }
    // padding: 0.5rem;
    // box-shadow: 0 1px 4px 3px rgba(0,0,0,0.15);
    .row {
      .col-sm-3, .col-sm-2 {
        .merchant-logo {
          margin: 0 5px;
          width: 45px;
          height: 45px;
        }
        span {
          font-weight: 500;
          font-size: 1rem;
          color: #000;
        }
        .institution-logo {
          width: 45px;
          margin-right: 5px;
        }
      }
    }
  }
  .auto-loader {
    display: block;
    height: 50px;
    width: 100%;
    text-align: center;
  }
  .tx-table-header {
    background: #ececec;
    .tx-header-cell {
      position: relative;
      padding: 0.75rem 0.5rem;
      h3 {
        margin: 0;
        font-weight: 500;
        color: #000;
        text-transform: capitalize;
      }
    }
    .tx-header-cell > h3 {
      margin-left: 8px;
    }
    .tx-header-cell.clickable {
      cursor: pointer;
      .sort-icon {
        position: absolute;
        top: 15px;
        right: 10px;
        font-size: 1.25rem;
      }
    }
  }
}


.tx-category-wrapper {
  select {
    font-weight: 600;
    padding: 0.25rem 0.4rem;
    height: calc(2rem + 2px);
    display: block;
    width: 95%;
    background: #E0E0E0;
    border-radius: 3px;
    option {
      font-weight: 600;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    &::-ms-expand {
      display: none;
    }
    &:focus {
      border-color: #cad1d7;
    }
  }
}

.tx-detail {
  .close-detail {
    position: relative;
    svg {
      position: absolute;
    right: 0;
    top: -13px;
    cursor: pointer;
    color: $danger;
    }
  }
  padding: 1.25rem 0.75rem;
  position: fixed;
  top: 4rem;
  right: 5px;
  width: 30%;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.25);
  .row {
    h5 {
      font-size: 1.1rem;
      color: #000;
    }
    .currency {
      color: #109CF1;
    }
    color: #000;
    .merchant-logo {
      height: 50px;
    }
    .tx-merchant-name {
      font-size: 1rem;
      display: inline-block;
      font-weight: 700;
      color: #000;
    }
    .tx-icons {
      font-size: 1.5rem;
    opacity: 0.5;
    margin-left: 2px;
    }
  }
}

.receipt {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  .fa-file {
    font-size: 2rem;
    color: #c4c4c4;
    margin-right: 10px;
  }
  .receipt-name{
    font-size: 0.85rem;
    display: block;
    width: 100%;
  }
  .fa-eye, .fa-trash {
    color: #000;
    color: #c4c4c4;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  .fa-eye {
    margin-right: 10px;
  }
}
