.query {
  .tab-content {
    .btn.float-left {
      border-radius: 4px;
    }
    .btn.float-left.run {
      background-color: #007AFF;
    }
  }
  .destination-sidebar {
    min-height: 200px;
    background: #fff;
    padding: 2rem 1.5rem;
    .connect-destination {
      border: none;
      background: #FFFFFF;
      border: 1px solid black;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
  .tab-content {
    .destinations {
      padding: 1rem 0;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 1px rgba(136, 136, 136, 0.24);
      margin: 0;
      .destination-row {    
        margin: 0.25rem -15px;
        padding: 0.5rem 0;
        &:hover {
          background-color: #F8F8F8;
        }
      }
    }
  }
}