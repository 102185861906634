.team {
  .permissions-card {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 1px rgba(136, 136, 136, 0.24);
    border-radius: 4px;
    padding: 1rem;
  }
  .member-list {
    padding: 1rem 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 1px rgba(136, 136, 136, 0.24);
    border-radius: 4px;
    .member-row {
      padding: 1rem 1.5rem;
      margin: 0 -1.5rem;
      &:hover {
        background-color: #f8f8f8;
        cursor: pointer;
      }
    }
    .member-header-row {
      .member-header-text {
        font-weight: 600;
        display: block;
      }
    }
  }
}