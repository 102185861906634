/* Balance change custom styles */
.balance-change-card {
    padding-top: 1rem;
    border-top: 1px solid #EDEBEB;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0; 


    .cash-change-span {
      font-size: 40px;
      color: #000;
      font-weight: 700;
    }

    .cash-out-text, .cash-in-text {
        font-size: 20px;
    }

    @media screen and (max-width: 1440px), (max-height: 900px) {
        .cash-out-text, .cash-in-text {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 991px){
        .cash-out-text, .cash-in-text{
            text-align: center!important;
        }
    }
}

.balance-display {
    margin: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'missionBalanceHeader'
      'missionBalanceBody'
      'missionBalanceFooter';
    grid-gap: 1rem;
}

.mission-balance-header {
    grid-area: missionBalanceHeader;
}

.mission-balance-body {
    grid-area: missionBalanceBody;
}

.mission-balance-footer {
    grid-area: missionBalanceFooter;
    vertical-align: middle;
}

.reports {
    background-color: #f4f6fb;
    .runway {
        font-weight: bold;
        font-size: 100px;
        padding: 0;
        margin: 0;
        .month {
            font-size: 30px;
        }
    }
    .metric {
        font-size: 20px;
        color: #000;
    }

    @media screen and (max-width: 1440px), (max-height: 900px) {
        .runway {
            font-size: 60px;
            .month {
                font-size: 30px;
            }
        }
        .metric {
            font-size: 16px;
        }
    }
}

.navigation-arrow {
    font-weight: 600;
    font-size: 20px;
    color: #000; 
    cursor: pointer;
}