//
// Forms
//


// Labels

.form-control-label {
    color: $gray-700;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
}


// Text inputs

.form-control {
    font-size: $input-btn-font-size;

    &:focus {
        &::placeholder {
            color: $input-focus-placeholder-color;
        }
    }
}


// Textarea

textarea[resize="none"] {
    resize: none!important;
}

textarea[resize="both"] {
    resize: both!important;
}

textarea[resize="vertical"] {
    resize: vertical!important;
}

textarea[resize="horizontal"] {
    resize: horizontal!important;
}


// Form input variations

// Muted input

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;
    box-shadow: none;

    &:focus {
        background-color: $input-focus-muted-bg;
    }
}

.alert-form {
    select.alert-select.form-control {
        padding: 0 0px!important;
        text-align: center!important;
        width: fit-content!important;
        outline: none!important;
        -webkit-appearance: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
        border-radius: 0!important;
        text-align-last: center!important;
        margin: 0 5px!important;
        padding: 0 5px!important;
        font-size: 1.05em!important;
    }
}


// Alternative input

.form-control-alternative {
    box-shadow: $input-alternative-box-shadow;
    border: 0;
    transition: box-shadow .15s ease;

    &:focus {
        box-shadow: $input-focus-alternative-box-shadow;
    }
}

// Size variations: Fixes to the bootstrap defaults

.form-control-lg {
    font-size: $font-size-base;
}
