.rc-mentions {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;
}
.rc-mentions > textarea,
.rc-mentions-measure {
  font-size: inherit;
  font-size-adjust: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  line-height: inherit;
  vertical-align: top;
  overflow: inherit;
  word-break: inherit;
  word-wrap: break-word;
  overflow-x: initial;
  overflow-y: auto;
  text-align: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  tab-size: inherit;
  direction: inherit;
}
.rc-mentions > textarea {
  border: none;
  width: 100%;
}
.rc-mentions-measure {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  color: transparent;
  z-index: -1;
}
.rc-mentions-dropdown {
  position: absolute;
}
.rc-mentions-dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rc-mentions-dropdown-menu-item {
  cursor: pointer;
}
.rc-mentions {
  font-size: 20px;
  border: 1px solid #999;
  border-radius: 3px;
  overflow: hidden;
}
.rc-mentions-dropdown {
  border: 1px solid #999;
  border-radius: 3px;
  background: #FFF;
}
.rc-mentions-dropdown-menu-item {
  padding: 4px 8px;
}
.rc-mentions-dropdown-menu-item-active {
  background: #e6f7ff;
}
.rc-mentions-dropdown-menu-item-disabled {
  opacity: 0.5;
}
.motion-zoom-enter,
.motion-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.motion-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.motion-zoom-enter.motion-zoom-enter-active,
.motion-zoom-appear.motion-zoom-appear-active {
  animation-name: rcTriggerZoomIn;
  animation-play-state: running;
}
.motion-zoom-leave.motion-zoom-leave-active {
  animation-name: rcTriggerZoomOut;
  animation-play-state: running;
}
@keyframes rcTriggerZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcTriggerZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
