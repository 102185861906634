.step-signup {
  .btn {
    background: rgb(0, 128, 205);
    padding: 0.5rem 2rem;
    border-radius: 0px;
  }
  .btn.next-btn {
    color: white;
    float: right;
  }
  .btn.add-entity {
    background: #fff!important;
    color: #747373!important;
    border: 1px solid #747373!important;
  }

  .steps-sidebar-container {
    padding-left: 0px;
    @media(max-width: 767px) {
      display:none;
    }
  }

  .steps-sidebar {
    .step-label {
      font-size: 1rem;
      font-weight: 500;
      color: gray;
      &:hover {
        color:black;
      }
    }
    .step-label.active {
      font-size: 1.05rem;
      font-weight: 700;
      color:black;
    }
  }

  .step-title {
    margin-top: 30px;
    font-size: 30px;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 0;
    }
  }

  .steps-container {
    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  .active-step-index {
    position: relative;
    .active-idx {
      font-size: 2.5em;
      color: #007AFF;
    }
    .inactive-idx {
      position: relative;
      color: #999999;
      top: 18px;
    }
  }

  .header-container {
    @media (max-width: 767px) {
      justify-content: center;
      align-items: center;
    }
  }
  .analyzing {
    .a-inst {
      max-width: 250px;
      max-height: 250px;
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
      img {
        width: 95%;
        margin: 0 2.5%;
      }
    }
    .a-inst:nth-child(2) {
      float: right;
    }
  }
}