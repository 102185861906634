.text-shadow {
    background-image: radial-gradient(rgba(0,0,0,0.2), rgba(255,255,255,0) 50%)
}

.upper-title {
    text-align: center;
    color: #fff;
    font-size: 20px; 
    text-transform: uppercase;
    margin-bottom: 20px; 
    font-family: sf-pro-text-light;
}

.title {
    text-align: center; 
    color: #fff; 
    font-size: 35px;
}

.login-button {
    border-color: #fff;
    border-radius: 2px;
    background-color: #fff;
    color: #A38EA4;
    font-size: 20px;
    width: 500px;
    padding: 15px;
    &:hover {
        color: #8e5691
    }
}

@media screen and (max-width: 1440px) {
    .upper-title {
        font-size: 16px;
    }

    .title {
        font-size: 30px;
    }

    .login-button {
        font-size: 16px;
        padding: 12px;
        width: 400px;
    }
}
