.alerts {
  .active-alerts {
    padding: 1rem 1.5rem;
    margin: 1rem 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 1px rgba(136, 136, 136, 0.24);
    border-radius: 4px;
    .alert-row {
      padding: 1rem 0;
      margin: 0 -1.5rem;
      &:hover {
        background-color: #f8f8f8;
        cursor: pointer;
      }
      .alert-account {
        display: block;
        font-weight: 700;
        text-align: right;
      }
      .account-currency {
        text-align: right;
      }
      .alert-meta {
        display: inline-flex;
        flex-direction: column;
        vertical-align: middle;
        .alert-text {
          font-size: 16px;
          display: block;
          font-weight: 700;
        }
      }
      .text-gray {
        font-size: 12px;
        display: block;
      }
    }
  }
  .alert-icon {
    height: 90%;
    font-size: 1.25rem;
    margin-right: 1.25rem;
    background: #eeeeee;
    display: inline-block;
    vertical-align: middle;
    padding: 0.5rem 0.7rem;
    border-radius: 50%;
    svg {
      font-size: 25px;
      vertical-align: sub;
      color: #003166;
    }
  }
  .alert-sidebar {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 1px rgba(136, 136, 136, 0.24);
    border-radius: 4px;
    margin-top: 9rem;
    min-height: 200px;
    margin-right: 1.5rem;
    margin-left: 0.25rem;
    padding: 1.5rem 1.25rem;
    h2 {
      margin-bottom: 0;
      margin-top: 1rem;
      .blue {
        color: #109CF1;
      }
    }
    .delete-alert-btn {
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
}

.create-alert-modal {
  .modal-content {
    .switch-wrapper {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;
      .switch {    
        width: 40px;
        height: 20px;
        .slider.round {
          &:before {    
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: .4s;
          }
        }
        input:checked + .slider:before {
          transform: translateX(18px);
        }
      }
    }
    .unsaved-dm, .slack-connect {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 13px;
        color: #888888;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .alert-step-sidebar, .alert-step-content {
    padding: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(136, 136, 136, 0.24);
    border-radius: 4px;
  }
  .alert-step-content {
    .sep {
      margin-left: 2px;
    }
    .alert-btn {
      background: #007AFF;
      border-radius: 2px;
      border: none;
      box-shadow: none;
      color: #fff;
    }
  }
  h5 {
    font-size: 16px;
  }
  .alert-step {
    font-size: 12px;
    color: #888888;
    margin-bottom: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  .alert-step.active {
    color: initial;
  }
}