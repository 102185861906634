/* Legal entites custom style elements */
.legal-entity-card {
  background-color: #fff;
  padding: 0.5rem 0;
  margin: 1rem 0.5rem;
  border: 1px solid #e2e2e2!important;
  border-radius: 2px!important;
  transition: 0.3s linear;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
  }
  img {
    width: 200px;
  }
  .select-btn {
    cursor: pointer;
    position: relative;
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
    margin-top: 25px;
    padding: 0.625rem 1.25rem;
    font-weight: 600;
    color: #040222;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 4px;
    &:hover {
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075)!important;
    }
  }
}

.legal-entity-card:hover {
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
}