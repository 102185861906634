.settings {

  .setting-card {
    max-width: 600px;
    min-width: 300px;
    padding: 2rem;
    background: #FFFFFF;
    border-radius: 4px;
    .save-settings {
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
}