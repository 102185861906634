#workflow {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'cashHeader details'
      'content details';
  }
  
.workflow-header { 
    grid-area: cashHeader;
    z-index: 2;
    box-shadow: 10px 0 10px -5px rgba(136, 136, 136, 0.24);
}

.workflow-body { 
    grid-area: content;
    z-index: 2;
    box-shadow: 10px 0 10px -5px rgba(136, 136, 136, 0.24);
}

#workflow-builder {
    height: 100vh;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'workflowBuilderSidebarLeft workflowBuilderBody workflowBuilderSidebarRight';
}

.workflow-builder-sidebar-left {
    box-shadow: 10px 0 10px -5px rgba(136, 136, 136, 0.24);
    background-color: #fff;
    grid-area: workflowBuilderSidebarLeft;
}

.workflow-builder-sidebar-right {
    z-index: 1;
    display: none;
    width: 350px;
    box-shadow: -5px 10px 10px 0px rgba(136, 136, 136, 0.24);
    grid-area: workflowBuilderSidebarRight;
    background-color: #fff;
    aside {
        padding: 1rem;
    }
}

.workflow-builder-sidebar-right-open {
    transition: all 0.5s;
    display: block;
}

.st-effect-1.st-menu-open .st-effect-1.st-menu {
	visibility: visible;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.st-effect-1.st-menu::after {
	display: none;
}


.workflow-builder-body {
    grid-area: workflowBuilderBody;
    height: 100vh;
}

  .item3 { 
    grid-area: details;
    width: 400px;
    background-color: #F4F6FB;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  #workflow-builder-action {
        width: 350px;
        background-color: #fff;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas:
          'workflowBuilderLeft workflowBuilderRight';
        &:hover {
            cursor: pointer;
            background-color: #f8f8f8;
        }
        padding: 1rem 0 1rem 0;
  }

  .workflow-builder-left {
    grid-area: workflowBuilderLeft;
    padding-left: 0;
    vertical-align: middle;
    img {
      height: 40px;
      margin-right: 15px;
    }
    .icon {
        height: 20px;
        margin-right: 0px;
        margin-left: 0px;
        cursor: grab;
    }
  }
  
  .workflow-builder-right {
    grid-area: workflowBuilderRight;
    .wbr-title {
      display: block;
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      font-family: 'sf-pro-text-semibold';
    }
    .wbr-description {
      display: block;
      text-align: left;
      color: #808292;
      font-size: 14px;
    }
  }


#workflow-card {
    width: 350px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'workflowCardHeader'
        'workflowCardFooter';
    box-shadow: 0px 4px 30px rgba(22, 33, 74, 0.05);
    padding: 1rem;
}

.workflow-card-header {
    grid-area: workflowCardHeader;
    
    img {
      height: 25px;
      margin-right: 10px;
    }
    span {
        font-family: 'sf-pro-text-semibold';
    }
    hr {
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
    }
    .title {
        display: block;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
        font-family: 'sf-pro-text-semibold';
    }
}

.workflow-card-footer {
    grid-area: workflowCardFooter;
    span {
        font-family: 'sf-pro-text-light';
    }
    .w-title {
      display: block;
      font-size: 14px;
      text-align: left;
      font-family: 'sf-pro-text-semibold';
  }
  .w-value {
    font-family: 'sf-pro-text-semibold';
    text-decoration: underline;
  }
}