.navbar .collapsing .navbar-toggler{
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i{
  margin-right: 4px;
}

.navbar {
  .dropdown-menu {
    background-color: rgba(0,0,0,0.2)!important;
    border: none;
    box-shadow: none;
    .dropdown-item {
      padding: 0.5rem;
      font-size: 1rem;
      &:hover {
        background-color: transparent!important;
      }
      .nav-link {
        padding: 0!important;
        &:hover {
          cursor: pointer;
          color: #ffffffa6!important;
        }
      }
    }
  }
}

@media (max-width: 767.98px){
  .navbar {
    .navbar-collapse.collapsing {
      margin-top: 75px;
    }
    .navbar-collapse.show {
      margin-top: 75px;
    }
    .command-bar-nav.navbar-nav {
      .nav-item {
        .form-control {
          background-color: #00000030;
        }
      }
    }
  }
}