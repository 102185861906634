.dashboard {
  .dashboard-header {
    background-color: #fff;
    .balance {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .text {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.dashboard-content {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  // grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'accountChart'
    'accountList';

  .accounts-chart {
    grid-area: accountChart;
  }

  .accounts-list {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    grid-area: accountList;
    overflow: scroll;
    height: 500px;
  }
}

@media screen and (max-width: 1440px), (max-height: 900px) {
  .dashboard-content {
    .accounts-list {
        height: 200px;
      }
  }
}




.account-card {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'accCardBankLogo accCardBankBalance';
  padding: 1rem;
  margin: 1rem 0.5rem;
  min-width: 500px;
  border: 1px solid #e2e2e2!important;
  border-radius: 2px!important;
  transition: 0.3s linear;
  height: 120px;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
  }
  .account-card-header {
    grid-area: accCardBankLogo;
    .account-details {
      display: inline-block;
      img {
        width: 40px;
        height: 40px;
      }
      .account-name {
        margin-top: 10px;
        display: block;
        overflow: hidden;
        font-weight: 600;
      }
      .account-currency {
        color: #888888;
      }
    }
  }
  .account-card-body {
    grid-area: accCardBankBalance;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;    
    flex-direction: column;
    .text-gray {
      font-size: 12px;
    }
  }
}


@media screen and (max-width: 1440px), (max-height: 900px) {
  .account-card {
    .account-card-header {
      .account-logo {
        display: inline-block;
        width: 50px;
        height: 50px;
        img {
          height: 100%;
        }
      }
      .account-details {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        vertical-align: middle;
        .account-name {
          font-weight: 600;
          font-size: 14px;
        }
        .account-currency {
          color: #888888;
        }
      }
    }
    .account-card-body {
      height: 80px;
    }
  }
}

.account-card.selected-account-card {
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(165, 165, 165, 0.5)!important;
}


.tx-header {
  padding: 1rem 0;
  margin-top: 10px;
  .text {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    display: block;
  }
  
}


.dashboard {
  .dashboard-transactions {
    .period-tx-wrapper {
      padding: 1rem 0;
      .period-tx {
        background-color: #fff;
        border-radius: 4px;
        padding: 1rem 0;
        .period-tx-details {
          position: relative;
          &:hover {
            cursor: pointer;
            background-color: #f8f8f8;
          }
          padding: 1rem;
          .details-2 {
            display: flex;
            .tx-meta-wrapper {
              .tx-amt {
                display: block;
                font-weight: 600;
                font-size: 17px;
                text-align: left;
              }
            }
          }
          .details-1 {
            padding-left: 0;
            img {
              height: 60px;
              margin-right: 10px;
            }
            .tx-detail-wrapper {
              display: inline-block;
              vertical-align: middle;
              .tx-title {
                display: block;
                font-weight: 600;
                font-size: 17px;
              }
            }
          }
        }
      }
      .period-text {
        font-size: 16px;
        font-weight: 600;
        margin-left: 20px;
        display: block;
      }
      .period-tx-details.selected {
        background-color: #f8f8f8;
      }
    }
  }
}

.dashboard {
  .tab-sidebar {
      margin-top: 1rem;
      padding: 0 1rem;
    .account-details-card {
      padding: 1rem;
      .account-meta {
        .all-balance-logo {
          height: 50px;
          width: 50px;
          display: inline-block;
          background: #eeeeee;
          border-radius: 50%;
          vertical-align: middle;
        }
        img {
          height: 50px;
        }
        .bank-account-logo {}
        .account-text {
          display: inline-block;
          padding-left: 5px;
          vertical-align: middle;
          h1 {
            font-size: 16px;
            margin-bottom: 0;
          }
        }
      }
      .account-balance-meta {
        h1 {
          font-size: 16px;
          margin-bottom: 0;
        }
        margin-right: 5px;
        vertical-align: middle;
        text-align: right;
        margin-top: 5px;
      }
      .text-gray {
        font-size: 12px;
      }
      .account-details-body {
        .row {
          padding-top: 1rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid #E9E9E9;
          .account-data-col {
            h3 {
              font-size: 16px;
            }
            span {
              color: #8898aa;
              font-size: 12px;
              .green {
                color: #43dea7!important;
              }
              .red {
                color: #e33500!important;
              }
              .period {
                color: #1082ff!important;
              }
            }
          }
        }
      }
    }
  }
}

