.cashflow-tooltip {
  text-align: center;
  display: inline-block;
  background: #fff!important;
  color: #32325d;    
  border: 1px solid #e9ecef !important;
  border-radius: 0.2rem;
  padding: 10px;
  font-size: 1.3rem;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}


.dashboard {
  .cashflow-chart-row {
    .col-md-3,.col-3,.col-lg-3 {
      background-color: #F4F6FB;
    }
  }
  .cash-flow-table {
    border: none;
    margin-right: 5px;
    padding: 1.5rem 1rem;
    .period-row {    
      padding-top: 0.5rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #E9E9E9;
      .cft-text {
        font-size: 18px;
        font-weight: 600;
      }
      .cft-meta {
        color: #888888;
        font-size: 13px;
      }
    }
  }
  .cashflow-chart {
    // font-family: $font-name, 'Open Sans', sans-serif!important;
    color: '#888';
    .x-axis-tick {
      .domain {
        display: none;
      }
      .tick {
        line {
          opacity: 0;
        }
        font-size: 0.9rem;
        text-transform: capitalize;
        letter-spacing: 1.5px;
        font-weight: 300;
      }
    }
    .y-axis-tick {
      .domain {
        display: none;
      }
      .tick {
        line {
          opacity: 0;
        }
        font-size: 0.9rem;
        text-transform: capitalize;
        letter-spacing: 1.5px;
        font-weight: 300;
      }
    }
  }
  g{
    .comparison-period, .primary-period {
      stroke: #00000040;
      stroke-width: 3px;
    }
    .primary-period {
      stroke-dasharray: 10;
    }
  }
}