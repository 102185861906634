body {
  --general-spacing: 0px 20px 0px 20px;
}

#app {
  height: 100vh;

  overflow: hidden; 
  min-width: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
    
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    'appHeader'
    'appMain'
    'appFooter';

  > header, > main, > footer {
    margin: var(--general-spacing);
  }
  
  > header {
    grid-area: appHeader;
  }
  
  > main {
    background-color: #fff;
    grid-area: appMain;
  }
  
  > footer {
    grid-area: appFooter;
  }
}

.main-head {
  grid-area: header;
}

.main-content {
  grid-area: main;
}

.main-footer {
  grid-area: footer;
}

#mission-control {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: 55% 45%;
  grid-template-areas:
    'balance reports'
    'balanceChanges reports';
}

.mission-control-balance {
  grid-area: balance;
}

.mission-control-balance-changes {
  grid-area: balanceChanges;
}

.mission-control-reports {
  grid-area: reports;
  width: 400px;
  @media (max-width: 1280px) {
    width: 350px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

#dashboard {
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-template-rows: 65px 1fr;
  grid-template-areas:
    'cashHeader details'
    'content details';
}

.item1 { 
  grid-area: cashHeader;
  z-index: 2;
  box-shadow: 10px 0 10px -5px rgba(136, 136, 136, 0.24);
}
.item2 { 
  grid-area: content;
  z-index: 2;
  box-shadow: 10px 0 10px -5px rgba(136, 136, 136, 0.24);
}
.item3 { 
  grid-area: details;
  width: 400px;
  background-color: #F4F6FB;
  @media (max-width: 1200px) {
    display: none;
  }
}

#transactions-content {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'transactionsHeader'
      'transactions';
}

.transactions-header {
  grid-area: transactionsHeader;
  color: #8898aa;
  font-size: 18px;
  padding: 20px;
}

.transactions-list {
  grid-area: transactions;
  overflow-y: scroll;
}

#transaction-row {
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem 0;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 13%;
  grid-template-areas:
    'left right';
  &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
  }
  padding: 1rem;
}

#transaction-row.selected {
  background-color: #f8f8f8;
}

.row-left {
  grid-area: left;
  padding-left: 0;
  img {
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .tx-detail-wrapper {
    display: inline-block;
    vertical-align: middle;
    .tx-title {
      display: block;
      font-weight: 600;
      font-size: 17px;
    }
  }
}

.row-right {
  grid-area: right;
  display: flex;
  .tx-amt {
    display: block;
    font-weight: 600;
    font-size: 17px;
    text-align: left;
  }
  .tx-bank {
    display: block;
    text-align: left;
  }
}

@media screen and (max-width: 1440px), (max-height: 900px) {
  .row-left {
    img {
      height: 50px;
    }
    .tx-detail-wrapper {
      .tx-title {
        font-size: 15px;
      }
    }
  }

  .row-right {
    .tx-amt {
      font-weight: 600;
      font-size: 15px;
    }
  }
}

#invoice-row {
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 2fr 1fr 13%;
  grid-template-areas:
    'left middle right';
  &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
  }
  padding: 1rem;
}

#transaction-row.selected {
  background-color: #f8f8f8;
}

.invoice-row-left {
  grid-area: left;
  padding-left: 0;
  img {
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .tx-detail-wrapper {
    display: inline-block;
    vertical-align: middle;
    .tx-title {
      display: block;
      font-weight: 600;
      font-size: 17px;
    }
  }
}

.invoice-row-middle {
  grid-area: middle;
}

.invoice-row-right {
  grid-area: right;
  display: flex;
  .tx-amt {
    display: block;
    font-weight: 600;
    font-size: 17px;
    text-align: left;
  }
  .tx-bank {
    display: block;
    text-align: left;
  }
}

#transactions-details {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'transactionsDetailsHeader'
    'transactionsDetailsAmount'
    'transactionsDetailsPaidOn'
    'transactionsDetailsNote'
    'transactionsDetailsReceipt'
    'transactionsDetailsChat';
    grid-gap: 10px;
}

.tx-details-header {
  grid-area: transactionsDetailsHeader;
}

.tx-details-amount {
  font-size: 25px;
  grid-area: transactionsDetailsAmount;
}

.tx-details-paid-on {
  grid-area: transactionsDetailsPaidOn;
}

.tx-details-note {
  grid-area: transactionsDetailsNote;
}

.tx-details-receipts {
  grid-area: transactionsDetailsReceipt;
}

.tx-details-chat {
  grid-area: transactionsDetailsChat;
}


#transactions-details-header {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-areas:
    'txDetailsHeaderImage txDetailsHeaderName'
    'txDetailsHeaderImage txDetailsHeaderWebsite'
    'txDetailsHeaderImage txDetailsHeaderCategory';
}

.tx-details-header-image {
  grid-area: txDetailsHeaderImage;
  border-radius: 50%;
  height: 70px;
  margin-top: 10px;
}

.tx-details-header-merchant-name {
  font-size: 20px;
  grid-area: txDetailsHeaderName
}

.tx-details-header-website {
  grid-area: txDetailsHeaderWebsite;
}

.tx-details-header-category {
  grid-area: txDetailsHeaderCategory;
  width: 200px;
}


#cashflow-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'cashFlowHeader'
    'cashFlowGraph';
}

.cash-flow-header {
  grid-area: cashFlowHeader;
  color: #8898aa;
  font-size: 18px;
  padding: 20px;
}

.cash-flow-graph {
  grid-area: cashFlowGraph;
}

#invoice-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'invoiceHeader invoiceHeader'
    'pdfView formFields';
  grid-gap: 10px;
}

.invoice-modal-header {
  grid-area: invoiceHeader;
}

.invoice-modal-pdf {
  grid-area: pdfView;
  overflow: scroll;
}

.invoice-modal-form-field {
  grid-area: formFields;
}

#create-alert-modal {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-areas:
    'createAlertHeader createAlertHeader'
    'subHeader subHeader'
    'steps createAlert';
  grid-gap: 20px;
}

.create-alert-modal-header {
  grid-area: createAlertHeader;
}

.create-alert-modal-sub-header {
  grid-area: subHeader;
}

.create-alert-modal-steps {
  grid-area: steps;
}

.create-alert-modal-create-zone {
  grid-area: createAlert;
}

.modal-content {
  border-radius: 1px;
}

#connect {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-areas:
    'connectSide connectMain'
    'connectSide connectMain'
    'connectSide connectMain'
    'connectSide connectMain'
    'connectSide connectMain';
  padding: 20px;
  grid-gap: 20px;
  height: 100%;
}

.connect-side {
  grid-area: connectSide;
}

.connect-main {
  grid-area: connectMain;
  height: 100%;
  overflow-y: scroll;
}

#connect-view {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'banks'
    'payments'
    'payroll'
    'accounting'
    'upload';
  grid-gap: 20px;
  overflow: hidden;
}

.connect-view-select-entity {
  grid-area: selectEntity;
}

.connect-view-banks {
  grid-area: banks;
}

.connect-view-payments {
  grid-area: payments;
}

.connect-view-payroll {
  grid-area: payroll;
}

.connect-view-accounting {
  grid-area: accounting;
}

.connect-view-upload {
  grid-area: upload;
}

.connect-row {
  display: grid;
  grid-template-columns: 150px 150px 150px 150px 150px 150px;
  grid-template-areas:
    'element0 element1 element2 element3 element4';
  grid-gap: 20px;
  // overflow-x: scroll;
}

.connect-row-element-0 {
  grid-area: element0;
  width: 150px;
  height: 150px;
}

.connect-row-element-1 {
  grid-area: element1;
  width: 150px;
  height: 150px;
}

.connect-row-element-2 {
  grid-area: element2;
  width: 150px;
  height: 150px;
}

.connect-row-element-3 {
  grid-area: element3;
  width: 150px;
  height: 150px;
}

.connect-row-element-4 {
  grid-area: element4;
  width: 150px;
  height: 150px;
}

#loading {
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'mainLoading'
    'footerLoading';
  grid-gap: 20px;
  height: '100vh';
}

.main-loading { 
  grid-area: mainLoading;
  height: 100vh;
}
.footer-loading { 
  grid-area: footerLoading;
  padding: 10px;

  h2 {
    text-align: center; 
    color: #fff; 
    font-size: 20px;
    margin-bottom: 20px;
    font-family: sf-pro-text-light;
    font-weight: lighter; 
  }
}



// Relevant styles
.image-stack {
  display: grid;
  position: relative; // imperative for the overlapping to work
}

.image-stack__item--bottom {
  grid-column: 1;
  grid-row: 1;
}

.image-stack__item--top {
  grid-row: 1;
  grid-column: 1;
  z-index: 1; // tells the browser to make this image on top
};

#entity-modal {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'entityModalHeader'
    'entityModalBody';
  grid-gap: 30px;
  padding: 20px;
}

.entity-modal-header {
  grid-area: entityModalHeader;
}

.entity-modal-body {
  grid-area: entityModalBody;
}

#reports-data {
  display: grid;
  grid-template-areas:
    'runway'
    'burn'
    'revenues'
    'net'
    'last';
}

.reports-data-runway {
  grid-area: runway;
}

.reports-data-month {
  margin-bottom: 10px;
  grid-area: month;
}

.reports-data-burn {
  margin-top: 10px;
  margin-bottom: 10px;
  grid-area: burn;
}

.reports-data-revenues {
  margin-top: 10px;
  margin-bottom: 10px;
  grid-area: revenues;
}

.reports-data-net {
  margin-top: 10px;
  margin-bottom: 10px;
  grid-area: net;
}

.reports-data-last {
  margin-top: 10px;
  margin-bottom: 10px;
  grid-area: last;
}

#reports-runway {
  display: grid;
  grid-template-areas:
    'runway'
    'month';
}

.reports-number {
  grid-area: runway;
}

.reports-month {
  grid-area: month;
}