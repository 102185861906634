.main-text {
    background-image: radial-gradient(rgba(0,0,0,0.5), rgba(255,255,255,0) 50%);
}

.greeting {
    color: white;
    text-align: center;
    font-size: 80px;
}
  
.metric {
    color: white;
    text-align: center;
    font-size: 40px;
}

@media screen and (max-width: 1440px), (max-height: 900px) {
    .greeting {
      font-size: 60px;
    }
    
    .metric {
        font-size: 30px;
    }
}

